@mixin menu-theme($theme) {
  $custom: map-get(map-get($theme, custom), color);

  $backgroundColor: mat-color($custom, background);
  $toolbarColor: mat-color($custom, toolbar);
  $textColor: mat-color($custom, text);
  $accentColor: mat-color($custom, accent);
  $activeColor: mat-color($accent-palette, 700);

  mat-toolbar.menu {
    color: $accentColor;
    background: $toolbarColor !important;

    button {
      &.active-link {
        background: $activeColor;
        color: white;
      }

      &.mat-icon-button{
        &:hover .mat-button-focus-overlay {
          opacity: 0.04;
        }
      }
    }
  }

  .mat-menu-panel.menu-toolbar-panel {
    button.mat-menu-item, mat-icon {
      color: $accentColor;
    }
  }
}