@mixin notification-theme($theme) {
  $custom: map-get(map-get($theme, custom), color);

  $backgroundColor: mat-color($custom, background);
  $borderColor: mat-color($custom, border);
  $accentColor: mat-color($custom, accent);
  $primaryColor: mat-color($custom, primary);
  $textColor: mat-color($custom, text);

  // Notification menu
  .mat-menu-panel.notification-menu-panel {
    .notification-action {
      border-bottom: 1px solid $borderColor;

      .read-delete {
        border-bottom: 1px solid $borderColor;
      }
    }
    mat-icon {
      color: $accentColor;
    }
  }

  // Basket notification
  .notification {
    border-bottom: 1px solid $borderColor;
    color: $accentColor;

    &.new {
      .notification-row-toolbar mat-icon.read,
      .notification-row .document-number {
        color: $primaryColor;
      }
    }

    &:hover{
      background: darken($backgroundColor, 2%);
    }
  }
}