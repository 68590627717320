@mixin header-theme($theme) {
  $custom: map-get(map-get($theme, custom), color);

  $toolbarColor: mat-color($custom, toolbar);
  $borderColor: mat-color($custom, border);
  $successColor: mat-color($custom, success);
  $accentColor: mat-color($custom, accent);
  $iconUpgrade: mat-color($yellow-palette, 300);

  mat-toolbar.header {
    color: $accentColor;
    background: $toolbarColor !important;
    border-bottom: 1px solid $borderColor;

    .header-action-container{
      mat-icon {
        color: $accentColor;
      }
    }

    button.button-upgrade {
      background: $successColor;
      color: white;
      mat-icon {
        color: $iconUpgrade;
      }
    }
  }

  .mat-menu-panel.user-toolbar-panel {
    button.mat-menu-item, mat-icon {
      color: $accentColor;
    }
  }
}