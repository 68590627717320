@mixin header-style() {
  mat-toolbar.header {
    min-height: 48px;

    mat-icon.portal-logo {
      width: 120px;
      height: 47px;
    }

    mat-icon.portal-logo-small {
      width: 30px;
      height: 27px;
      display: none;
    }

    .header-action-container{
      display: flex;
      gap: 12px;
      height: 36px;

      .selected-organization span {
        display: inline-block;
        text-align: center;
        text-decoration: none;
        width: 180px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }
  }

  @media screen and (max-width: 1024px) {
    .user-profile{
      visibility: hidden;
    }  
  }

  @media screen and (max-width: 600px) {
    mat-icon.portal-logo {
      display: none;
    }
    mat-icon.portal-logo-small {
      display: block !important;
    }
    .hide-medium {
      display: none;
    }
  }
}