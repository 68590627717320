@mixin menu-mobile-style() {
  button.sidenav-button-toggle {
    position: fixed;
    top: 5px;
    right: 15px;
    z-index: 1000;
  }

  mat-sidenav-container.mobile-menu-sidenav {
    z-index: 9999;
    .mat-drawer-inner-container {
      display: flex;
      flex-direction: column;
    }
    mat-sidenav {
      min-width: 300px;
      mat-nav-list {
        padding: 0;
        .mat-list-item, mat-expansion-panel-header {
          font-size: 14px;
          line-height: 16px;
          height: 40px;
          .mat-icon {
            font-size: 16px;
            width: 16px;
            height: 16px;
            line-height: 17px;
          }
        }

        .mat-list-item {
          padding: 0;
          .mat-list-item-content {
            gap: 8px;
          }
        }

        mat-expansion-panel {
          border-radius: 0 !important;
          box-shadow: none !important;
          
          mat-expansion-panel-header, mat-expansion-panel-header.mat-expanded {
            padding: 0 16px !important;
            .mat-content {
              gap: 8px;
              flex-direction: row !important;
            }
            &.mat-expanded {
              height: 40px;
              padding: 0 16px !important;
            }
          }
          .mat-expansion-panel-body {
            padding: 4px 16px;
            .sub-list-item {
              border-radius: 6px;
            }
          }
        }
      }
    }
  }
}