@mixin base-theme($theme) {
  $custom: map-get(map-get($theme, custom), color);

  $textColor: mat-color($custom, text);
  $toolbarColor: mat-color($custom, toolbar);
  $borderColor: mat-color($custom, border);

  .module-container {
    background-color: $toolbarColor;
    color: $textColor;

    // Welcome page background for image
    &:has(app-welcome) {
      background: linear-gradient(0deg, rgba($toolbarColor, .8), rgba($toolbarColor, .8)),
        url(../assets/images/welcome-background.jpg),
        #FFFFFF;
      background-size: cover;
    }
  }

  // Language Flag
  img.language-flag {
    border: 1px solid $borderColor;
  }
}