@mixin menu-mobile-theme($theme) {
  $custom: map-get(map-get($theme, custom), color);

  $toolbarColor: mat-color($custom, toolbar);
  $primaryColor: mat-color($custom, primary);
  $accentColor: mat-color($custom, accent);
  $activeColor: mat-color($accent-palette, 700);
  $borderColor: mat-color($custom, border);
  
  button.sidenav-button-toggle {
    mat-icon {
      color: $accentColor;
    }
  }

  mat-sidenav-container.mobile-menu-sidenav {
    mat-sidenav {
      background: $toolbarColor !important;
      mat-nav-list {
        .mat-list-item, mat-expansion-panel-header {
          color: $accentColor;
        }

        mat-expansion-panel {
          background: transparent !important;
          border-bottom: 1px solid $borderColor;

          .mat-expansion-panel-body {
            .sub-list-item {
              &.active-link {
                background: $activeColor !important;
                color: white;
              }
            }
          }
        }

        .logout-button{
          color: $primaryColor;
        }
      }
    }
  }
}