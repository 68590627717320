@mixin menu-style() {
  mat-toolbar.menu {
    padding: 0;
    flex-wrap: wrap;

    button {
      border-radius: 0;
      
      &.mat-icon-button{
        &:hover .mat-button-focus-overlay {
          opacity: 0.04;
        }
        width: 65px;
        height: 36px;
        display: flex;
        align-items: center;
        justify-content: center;
        mat-icon {
          font-size: 16px !important;
          width: 16px !important;
          height: 18px !important;
          line-height: 17px !important;
        }
      }
    }
  }
}