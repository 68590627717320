
@mixin footer-style() {
  mat-toolbar.mat-toolbar.footer {
    min-height: 50px;
    gap: 16px;
    flex-wrap: wrap;
  }

  // Cookie settings
  .cdk-overlay-pane.cookie-settings {
    min-width: 100% !important;
    margin-bottom: 50px !important; // according to footer height

    mat-bottom-sheet-container {
      min-width: 100%;
      max-height: 100%;
      padding: 0;
      color: white;
      box-shadow: none;

      app-cookie-settings-overview {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 70%;
        margin: 0 auto;
        gap: 8px;
        padding: 8px;

        h4 {
          font-size: 18px;
          font-weight: 400;
          margin-top: 8px;
          margin-bottom: 8px;
        }

        mat-divider {
          width: 100%;
        }

        .cookie-container {
          gap: 12px;

          a {
            text-decoration: underline;
          }

          hr {
            width: 100%;
          }

          mat-expansion-panel {
            background: transparent;
            box-shadow: none;
            mat-expansion-panel-header {
              padding: 0 !important;
              height: auto;
              .mat-expansion-panel-header-title {
                text-decoration: underline;
                font-weight: 400;
                font-size: 14px;
              }
            }
            .mat-expansion-panel-body {
              padding: 0 0 0 12px;
            }
          }

          .cookie-description{
            display: flex;
            flex-direction: column;
            flex-shrink: 1;
          }

          .cookie-buttons {
            display: flex;
            flex-direction: column;
            gap: 12px;
          }
        }
      }
    }
  }

  @media screen and (max-width: 1024px) {
    .cdk-overlay-pane.cookie-settings {
      margin-bottom: 0px !important;
    }
  }

  @media screen and (max-width: 1440px) {
    .hide-large {
      display: none;
    }
  }

  @media screen and (max-width: 768px) {
    app-cookie-settings-overview {
      .cookie-container {
        flex-wrap: wrap;
      }
      .cookie-buttons {
        flex: 100%;
      }
    }
  }
}