@mixin footer-theme($theme) {
  $custom: map-get(map-get($theme, custom), color);

  $backgroundColor: mat-color($custom, background);
  $primaryColor: mat-color($custom, primary);
  $cookieBackground: mat-color($accent-palette, 700);

  mat-toolbar.mat-toolbar.footer {
    background: $backgroundColor;
  }

  // Cookie settings
  .cdk-overlay-pane.cookie-settings {
    mat-bottom-sheet-container {
      background: $cookieBackground;
      color: white;

      app-cookie-settings-overview {
        .cookie-container {
          a {
            color: white;
            text-decoration: underline;
          }

          hr {
            background: $primaryColor;
            color: $primaryColor;
            border-color: $primaryColor;
          }

          mat-expansion-panel {
            background: transparent;
            mat-expansion-panel-header {
              .mat-expansion-panel-header-title {
                color: white;
              }
            }
            .mat-expansion-panel-body {
              color: white;
            }
          }
        }
      }
    }
  }
}