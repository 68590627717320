@import './themes/app.theme.scss';

html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
  
  * {
    font-family: 'Inter', sans-serif;
  }
}
 
//It's needed for some old components and libraries
@font-face {
  font-family: "Glyphicons Halflings";
  src: url("assets/fonts/glyphicons-halflings-regular.eot");
  src: url("assets/fonts/glyphicons-halflings-regular.eot?#iefix") format("embedded-opentype"), url("assets/fonts/glyphicons-halflings-regular.woff2") format("woff2"), url("assets/fonts/glyphicons-halflings-regular.woff") format("woff"), url("assets/fonts/glyphicons-halflings-regular.ttf") format("truetype"), url("assets/fonts/glyphicons-halflings-regular.svg#glyphicons_halflingsregular") format("svg");
}