@mixin welcome-style() {
  app-welcome {
    .welcome-content {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      height: 100%;
      gap: 80px;
      .welcome-left-content {
        display: flex;
        flex-direction: column;
        gap: 12px;
        .welcome-portal-logo {
          width: 200px;
          height: 80px;
        }
        .welcome-text h2 {
          font-size: 24px;
          margin-top: 0;
        }
        .action-button{
          display: flex;
          gap: 10px;
        }  
      }
      .welcome-image {
        height: 380px;
      }
    }
  }
}