@font-face {
  font-family: 'Inter';
  src: url('/assets/fonts/Inter-Regular.ttf') format('truetype');
  font-display: swap;
  font-style: normal;
  font-weight: normal;
}

@mixin typo-page-title() {
  font-size: 24px;
  font-family: "Inter";
  font-weight: 500;
  font-style: normal;
  line-height: 32px;
  text-decoration: none;
  text-transform: none;
}
@mixin typo-ui-h-1() {
  font-size: 24px;
  font-family: "Inter";
  font-weight: 500;
  font-style: normal;
  line-height: 32px;
  text-decoration: none;
  text-transform: none;
}
@mixin typo-ui-h-2() {
  font-size: 20px;
  font-family: "Inter";
  font-weight: 600;
  font-style: normal;
  line-height: 28px;
  text-decoration: none;
  text-transform: none;
}
@mixin typo-ui-h-3() {
  font-size: 18px;
  font-family: "Inter";
  font-weight: 600;
  font-style: normal;
  line-height: 24px;
  text-decoration: none;
  text-transform: none;
}
@mixin typo-ui-h-4() {
  font-size: 16px;
  font-family: "Inter";
  font-weight: 600;
  font-style: normal;
  line-height: 20px;
  text-decoration: none;
  text-transform: none;
}
@mixin typo-ui-h-5() {
  font-size: 14px;
  font-family: "Inter";
  font-weight: 600;
  font-style: normal;
  line-height: 18px;
  text-decoration: none;
  text-transform: none;
}
@mixin typo-ui-h-6() {
  font-size: 12px;
  font-family: "Inter";
  font-weight: 600;
  font-style: normal;
  line-height: 16px;
  text-decoration: none;
  text-transform: none;
}
@mixin typo-ui-text-md-regular() {
  font-size: 16px;
  font-family: "Inter";
  font-weight: 400;
  font-style: normal;
  line-height: 20px;
  text-decoration: none;
  text-transform: none;
}
@mixin typo-ui-text-md-medium() {
  font-size: 16px;
  font-family: "Inter";
  font-weight: 500;
  font-style: normal;
  line-height: 20px;
  text-decoration: none;
  text-transform: none;
}
@mixin typo-ui-text-md-semibold() {
  font-size: 16px;
  font-family: "Inter";
  font-weight: 600;
  font-style: normal;
  line-height: 20px;
  text-decoration: none;
  text-transform: none;
}
@mixin typo-ui-text-md-bold() {
  font-size: 16px;
  font-family: "Inter";
  font-weight: 700;
  font-style: normal;
  line-height: 20px;
  text-decoration: none;
  text-transform: none;
}
@mixin typo-ui-text-sm-regular() {
  font-size: 14px;
  font-family: "Inter";
  font-weight: 400;
  font-style: normal;
  line-height: 18px;
  text-decoration: none;
  text-transform: none;
}
@mixin typo-ui-text-sm-medium() {
  font-size: 14px;
  font-family: "Inter";
  font-weight: 500;
  font-style: normal;
  line-height: 18px;
  text-decoration: none;
  text-transform: none;
}
@mixin typo-ui-text-sm-semibold() {
  font-size: 14px;
  font-family: "Inter";
  font-weight: 600;
  font-style: normal;
  line-height: 18px;
  text-decoration: none;
  text-transform: none;
}
@mixin typo-ui-text-sm-bold() {
  font-size: 14px;
  font-family: "Inter";
  font-weight: 700;
  font-style: normal;
  line-height: 18px;
  text-decoration: none;
  text-transform: none;
}
@mixin typo-ui-text-xs-regular() {
  font-size: 13px;
  font-family: "Inter";
  font-weight: 400;
  font-style: normal;
  line-height: 18px;
  text-decoration: none;
  text-transform: none;
}
@mixin typo-ui-text-xs-medium() {
  font-size: 13px;
  font-family: "Inter";
  font-weight: 500;
  font-style: normal;
  line-height: 18px;
  text-decoration: none;
  text-transform: none;
}
@mixin typo-ui-text-xs-semibold() {
  font-size: 13px;
  font-family: "Inter";
  font-weight: 600;
  font-style: normal;
  line-height: 18px;
  text-decoration: none;
  text-transform: none;
}
@mixin typo-ui-text-xs-bold() {
  font-size: 13px;
  font-family: "Inter";
  font-weight: 700;
  font-style: normal;
  text-decoration: none;
  text-transform: none;
}
@mixin typo-ui-text-xxs-regular() {
  font-size: 12px;
  font-family: "Inter";
  font-weight: 400;
  font-style: normal;
  line-height: 16px;
  text-decoration: none;
  text-transform: none;
}
@mixin typo-ui-text-xxs-medium() {
  font-size: 12px;
  font-family: "Inter";
  font-weight: 500;
  font-style: normal;
  line-height: 16px;
  text-decoration: none;
  text-transform: none;
}
@mixin typo-ui-text-xxs-semibold() {
  font-size: 12px;
  font-family: "Inter";
  font-weight: 600;
  font-style: normal;
  line-height: 16px;
  text-decoration: none;
  text-transform: none;
}
@mixin typo-ui-text-xxs-bold() {
  font-size: 12px;
  font-family: "Inter";
  font-weight: 700;
  font-style: normal;
  line-height: 16px;
  text-decoration: none;
  text-transform: none;
}
@mixin typo-ui-text-xxxs-regular() {
  font-size: 10px;
  font-family: "Inter";
  font-weight: 400;
  font-style: normal;
  line-height: 14px;
  text-decoration: none;
  text-transform: none;
}
@mixin typo-ui-text-xxxs-medium() {
  font-size: 10px;
  font-family: "Inter";
  font-weight: 500;
  font-style: normal;
  line-height: 14px;
  text-decoration: none;
  text-transform: none;
}
@mixin typo-ui-text-xxxs-semibold() {
  font-size: 10px;
  font-family: "Inter";
  font-weight: 600;
  font-style: normal;
  line-height: 14px;
  text-decoration: none;
  text-transform: none;
}
@mixin typo-ui-text-xxxs-bold() {
  font-size: 10px;
  font-family: "Inter";
  font-weight: 700;
  font-style: normal;
  line-height: 14px;
  text-decoration: none;
  text-transform: none;
}
@mixin typo-h-1() {
  font-size: 36px;
  font-family: "Inter";
  font-weight: 500;
  font-style: normal;
  letter-spacing: -0.02em;
  text-decoration: none;
  text-transform: none;
  line-height: 44px;
}
@mixin typo-h-2() {
  font-size: 30px;
  font-family: "Inter";
  font-weight: 500;
  font-style: normal;
  line-height: 36px;
  text-decoration: none;
  text-transform: none;
}
@mixin typo-h-3() {
  font-size: 24px;
  font-family: "Inter";
  font-weight: 600;
  font-style: normal;
  line-height: 30px;
  text-decoration: none;
  text-transform: none;
}
@mixin typo-h-4() {
  font-size: 18px;
  font-family: "Inter";
  font-weight: 600;
  font-style: normal;
  line-height: 22px;
  text-decoration: none;
  text-transform: none;
}
@mixin typo-h-5() {
  font-size: 16px;
  font-family: "Inter";
  font-weight: 600;
  font-style: normal;
  line-height: 20px;
  text-decoration: none;
  text-transform: none;
}
@mixin typo-h-6() {
  font-size: 14px;
  font-family: "Inter";
  font-weight: 600;
  font-style: normal;
  text-decoration: none;
  text-transform: none;
}
@mixin typo-body-md() {
  font-size: 16px;
  font-family: "Inter";
  font-weight: 400;
  font-style: normal;
  text-decoration: none;
  text-transform: none;
}
@mixin typo-body-sm() {
  font-size: 14px;
  font-family: "Inter";
  font-weight: 400;
  font-style: normal;
  text-decoration: none;
  text-transform: none;
  line-height: 17px;
}
@mixin typo-caption() {
  font-size: 12px;
  font-family: "Inter";
  font-weight: 400;
  font-style: normal;
  text-decoration: none;
  text-transform: none;
  line-height: 15px;
}

//typo-page-title
//typo-ui-h-1
//typo-ui-h-2
//typo-ui-h-3
//typo-ui-h-4
//typo-ui-h-5
//typo-ui-h-6
//typo-ui-text-md-regular
//typo-ui-text-md-medium
//typo-ui-text-md-semibold
//typo-ui-text-md-bold
//typo-ui-text-sm-regular
//typo-ui-text-sm-medium
//typo-ui-text-sm-semibold
//typo-ui-text-sm-bold
//typo-ui-text-xs-regular
//typo-ui-text-xs-medium
//typo-ui-text-xs-semibold
//typo-ui-text-xs-bold
//typo-ui-text-xxs-regular
//typo-ui-text-xxs-medium
//typo-ui-text-xxs-semibold
//typo-ui-text-xxs-bold
//typo-ui-text-xxxs-regular
//typo-ui-text-xxxs-medium
//typo-ui-text-xxxs-semibold
//typo-ui-text-xxxs-bold
//typo-h-1
//typo-h-2
//typo-h-3
//typo-h-4
//typo-h-5
//typo-h-6
//typo-body-md
//typo-body-sm
//typo-caption

.typo-page-title {
  @include typo-page-title;
}
.typo-ui-h-1 {
  @include typo-ui-h-1;
}
.typo-ui-h-2 {
  @include typo-ui-h-2;
}
.typo-ui-h-3 {
  @include typo-ui-h-3;
}
.typo-ui-h-4 {
  @include typo-ui-h-4;
}
.typo-ui-h-5 {
  @include typo-ui-h-5;
}
.typo-ui-h-6 {
  @include typo-ui-h-6;
}
.typo-ui-text-md-regular {
  @include typo-ui-text-md-regular;
}
.typo-ui-text-md-medium {
  @include typo-ui-text-md-medium;
}
.typo-ui-text-md-semibold {
  @include typo-ui-text-md-semibold;
}
.typo-ui-text-md-bold {
  @include typo-ui-text-md-bold;
}
.typo-ui-text-sm-regular {
  @include typo-ui-text-sm-regular;
}
.typo-ui-text-sm-medium {
  @include typo-ui-text-sm-medium;
}
.typo-ui-text-sm-semibold {
  @include typo-ui-text-sm-semibold;
}
.typo-ui-text-sm-bold {
  @include typo-ui-text-sm-bold;
}
.typo-ui-text-xs-regular {
  @include typo-ui-text-xs-regular;
}
.typo-ui-text-xs-medium {
  @include typo-ui-text-xs-medium;
}
.typo-ui-text-xs-semibold {
  @include typo-ui-text-xs-semibold;
}
.typo-ui-text-xs-bold {
  @include typo-ui-text-xs-bold;
}
.typo-ui-text-xxs-regular {
  @include typo-ui-text-xxs-regular;
}
.typo-ui-text-xxs-medium {
  @include typo-ui-text-xxs-medium;
}
.typo-ui-text-xxs-semibold {
  @include typo-ui-text-xxs-semibold;
}
.typo-ui-text-xxs-bold {
  @include typo-ui-text-xxs-bold;
}
.typo-ui-text-xxxs-regular {
  @include typo-ui-text-xxxs-regular;
}
.typo-ui-text-xxxs-medium {
  @include typo-ui-text-xxxs-medium;
}
.typo-ui-text-xxxs-semibold {
  @include typo-ui-text-xxxs-semibold;
}
.typo-ui-text-xxxs-bold {
  @include typo-ui-text-xxxs-bold;
}
.typo-h-1 {
  @include typo-h-1;
}
.typo-h-2 {
  @include typo-h-2;
}
.typo-h-3 {
  @include typo-h-3;
}
.typo-h-4 {
  @include typo-h-4;
}
.typo-h-5 {
  @include typo-h-5;
}
.typo-h-6 {
  @include typo-h-6;
}
.typo-body-md {
  @include typo-body-md;
}
.typo-body-sm {
  @include typo-body-sm;
}
.typo-caption {
  @include typo-caption;
}

/** New Typography */

//typo-ui-text-lg-regular
//typo-ui-text-lg-medium
//typo-ui-text-lg-semibold
//typo-ui-text-lg-bold

@mixin typo-ui-text-lg-regular() {
  font-family: "Inter";
  font-style: normal;
  text-decoration: none;
  text-transform: none;
  font-size: 18px;
  line-height: 22px;
  font-weight: 400;
}

@mixin typo-ui-text-lg-medium() {
  font-family: "Inter";
  font-style: normal;
  text-decoration: none;
  text-transform: none;
  font-size: 18px;
  line-height: 22px;
  font-weight: 500;
}

@mixin typo-ui-text-lg-semibold() {
  font-family: "Inter";
  font-style: normal;
  text-decoration: none;
  text-transform: none;
  font-size: 18px;
  line-height: 22px;
  font-weight: 600;
}

@mixin typo-ui-text-lg-bold() {
  font-family: "Inter";
  font-style: normal;
  text-decoration: none;
  text-transform: none;
  font-size: 18px;
  line-height: 22px;
  font-weight: 700;
}

.typo-ui-text-lg-regular {
  @include typo-ui-text-lg-regular();
}

.typo-ui-text-lg-medium {
  @include typo-ui-text-lg-medium();
}

.typo-ui-text-lg-semibold {
  @include typo-ui-text-lg-semibold();
}

.typo-ui-text-lg-bold {
  @include typo-ui-text-lg-bold();
}


