@mixin notification-style() {
  // Notification component
  .notification-menu-container {
    .notification-button {
      .mat-badge-content {
        top: -4px !important;
        right: -5px !important;
      }
    }
  }

  // Notification menu
  .mat-menu-panel.notification-menu-panel {
    .notification-action {
      display: flex;
      flex-direction: column;
      width: 100%;
      
      .read-delete {
        display: flex;
        justify-content: space-between;
        padding: 8px 4px;
      }
    }
    mat-icon {
      cursor: pointer;
      width: 16px;
      height: 16px;
      font-size: 16px;
      line-height: 17px;
    }

    .search-container {
      display: flex;
      mat-form-field {
        margin-bottom: 5px;
        max-width: 160px;
      }
    }

    .notification-container {
      max-height: 390px;
      overflow: auto;
      font-size: 12px;
    }
  }

  // Basket notification
  .notification {
    display: flex;
    flex-direction: column;
    padding: 4px 5px 4px 10px;
    gap: 2px;

    .notification-row-toolbar {
      display: flex;
      justify-content: flex-start;
    }

    .notification-row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;

      .document-number {
        font-weight: 600;
      }

      &.delete-notification {
        align-self: center;
        padding: 0;
      }
    }
  }
}