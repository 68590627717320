@mixin dialog-theme($theme) {
  $custom: map-get(map-get($theme, custom), color);

  $accentColor: mat-color($custom, accent);
  $iconBackground: mat-color($gray-true-palette, 100);
  $borderColor: mat-color($custom, border);
  
  .tsc-dialog {
    .tsc-dialog__header {
      .tsc-dialog__header__icon {
        background-color: $iconBackground;
        color: $accentColor;
      }

      .tsc-dialog__header__title {
        display: flex;
        flex-direction: column;
        flex-grow: 2;
      }

      .tsc-dialog__header__close {
        cursor: pointer;
      }
    }

    .tsc-dialog__actions {
      border-top: 1px solid $borderColor;
    }
  }
}