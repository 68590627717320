@mixin dialog-style() {
  .tsc-dialog {
    .tsc-dialog__header {
      display: flex;
      gap: 12px;
      .tsc-dialog__header__icon {
        padding: 10px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 50px 0 0;
        height: 50px;
      }

      .tsc-dialog__header__title {
        display: flex;
        flex-direction: column;
        flex-grow: 2;
        gap: 6px;
      }

      .tsc-dialog__header__close {
        cursor: pointer;
      }
    }

    .tsc-dialog__content{
      padding: 12px 24px;
    }
    
    .tsc-dialog__actions button{
      flex: 1;
      max-width: 100%;
    }
  }
}