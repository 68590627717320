@mixin base-style() {
  .main-container{
    height: 100%;
    .main-loader {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      width: 100%;
      position: absolute;
      background: rgba(0, 0, 0, 0.1);
      z-index: 9999;
    }
    app-menu-mobile {
      display: none;
    }
    app-menu {
      display: block;
    }
  }

  main {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }

  .module-container {
    flex-grow: 2;
    display: flex;
    overflow: auto;
    padding: 16px;

    // Apply display flex grow to the first remote module html element
    > *:not(router-outlet) {
      flex-grow: 2;
      width: 100%;
    }

    // Used by unauthorized and error pages
    .module-container-card {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .mat-card-header {
        align-items: center;
        mat-icon {
          font-size: 52px;
          height: 52px;
          width: 52px;
        }
      }

      .mat-card-content .content {
        font-weight: 400;
      }
    }
  }

  // Language Flag
  img.language-flag {
    width: 22px;
    border-radius: 4px;
    margin-right: 8px;
  }

  // Positioning
  .spacer {
    flex: 1 1 auto;
  }

  .flex {
    display: flex;
  }

  // Responsive
  @media screen and (max-width: 1024px) {
    app-menu-mobile {
      display: block !important;
    }
    app-menu, app-footer {
      display: none !important;
    }
  }

  @media (hover: none) {
    .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
        background: transparent;
    }
  }
}